.search-suggest-override p {
  margin: 0;
  cursor: pointer;
}

.search-history-box {
  border-top: 1px solid #707070;
}

.search-did-you-mean {
  margin-top: -28px;
}

/** could use .typography instead of p **/
.table-over-rides td p {
  margin: 9px 0;
}

.table-over-rides td:not([type="link"]):not([type="tag"]) p {
  color: #000;
}
.table-over-rides td[type="tag"] p {
  margin: 0;
}
