@import url("@lions/ui-components/dist/styles.css");

/* Temporary resets so support Horizon components  */
body {
  margin: 0;
  line-height: 1.2;
}

main,
details {
  display: block;
}

section p {
  margin: 1.6rem 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

/* Overwrite for the account form on the /account page */
/* TODO: Remove when the form component gets replaced with the UI components library */
form h3 {
  font-weight: bold;
  font-size: 2.2rem;
  margin: 2.4rem 0 1.2rem;
}
